:root {
  --node-width: 10px;
  --node-font-size: 20px;
  --node-text-background-opacity: 0;
  --flow-opacity: .5;
}

.watermark {
  opacity: .85;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 12pt;
}

.links path {
  opacity: var(--flow-opacity);
}

.nodes text {
  font-family: Open Sans, Arial, sans-serif;
  font-weight: 600;
  font-size: var(--node-font-size);
}

.nodes .node-body {
  width: var(--node-width);
  opacity: .85;
}

.nodes .node-text-bg {
  opacity: var(--node-text-background-opacity);
  fill: #fff;
  rx: 3;
}

.copy-link-button:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  border: 10px solid #0000;
  border-top-color: #282828;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  transition: all .5s;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
}

.copy-link-button:after {
  text-transform: none;
  content: attr(data-tooltip);
  color: #fff;
  opacity: 0;
  pointer-events: none;
  background: #282828;
  border-radius: 4px;
  width: 5rem;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
}

.copy-link-button.is-clicked:before, .copy-link-button.is-clicked:after {
  opacity: 1;
}

.copy-link-button.is-clicked:before {
  transform: translate3d(-50%, calc(-100% - 18px), 0);
}

.copy-link-button.is-clicked:after {
  transform: translate3d(-50%, calc(-100% - 16px), 0);
}
/*# sourceMappingURL=index.cbf6cfba.css.map */
