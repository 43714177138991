@import "core.css";

:root {
    --node-width: 10px;
    --node-font-size: 20px;
    --node-text-background-opacity: 0;
    --flow-opacity: 0.5;
}

.watermark {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 12pt;
    opacity: 0.85;
}

.links path {
    opacity: var(--flow-opacity);
}

.nodes text {
    font-weight: 600;
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: var(--node-font-size);
}

.nodes .node-body {
    width: var(--node-width);
    opacity: 0.85;
}

.nodes .node-text-bg {
    opacity: var(--node-text-background-opacity);
    fill: white;
    rx: 3;
}

.copy-link-button {
    &:before {
        content: "";
        position: absolute;
        opacity: 0;
        pointer-events: none;
        left: 50%;
        transform: translate3d(-50%,0%,0);
        transition: all 0.5s ease;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #282828 transparent transparent transparent;
    }

    &:after {
        text-transform: none;
        content: attr(data-tooltip);
        position: absolute;
        color: #fff;
        background: #282828;
        width: 5rem;
        opacity: 0;
        pointer-events: none;
        left: 50%;
        top: 0;
        border-radius: 4px;
        transform: translate3d(-50%,0%,0);
        transition: all 0.5s ease;
    }
    &.is-clicked {
        &:before, &:after {
            opacity: 1;
        }
        &:before {
            transform: translate3d(-50%,calc(-100% - 18px),0);
        }
        &:after {
            transform: translate3d(-50%,calc(-100% - 16px),0);
        }
    }
}
